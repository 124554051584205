import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

import SEOHero from "../images/svg/SearchEngineOptimization.svg"
import { BsArrowReturnRight } from "react-icons/bs"
import { Container, ListGroup } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const seo = () => {
  return (
    <Layout>
      <Seo
        title="Optimize your website for increased exposure - SEO"
        description="TheSoftCode SEO services offers a comprehensive approach to increase your website rankings organically on Google, Yahoo, Bing and more."
        keyword="SEO, Search Engine Optimization"
      />
      <Container fluid className="min-vh-100 position-relative">
        {/* <br />
        <div className="position-absolute text-white mytopleft d-none">
          <h1 className="text3d fs-3 text-center">Search Engine Optimization</h1>
        </div> */}
        <br /><br /><br />
        <Row>
          <Col sm={12}>
          <span className="text-white end-0 me-5">
          <h1 className="text3d fs-3 text-center">Search Engine Optimization</h1>
        </span>
          </Col>
          <Col sm={12} className="text-center">
          <SEOHero width="80%" />
          </Col>
        </Row>
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <br />
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center">
          <p
            className="bg-text fs-5 text-wrap"
            data-bg-text="SEO"
            data-aos="zoom-in-right"
          >
            It has become popular that having a hosted website is not just
            enough if you desire to be found online. Target audience should be
            able to find your services without much effort, they do not need to
            keep digging the internet before they find you even for the first
            time. There are a lot of sophisticated strategies to implement for
            the site to be easily located otherwise it will be as good as
            non-existence. Getting a site found on search engine result pages
            SERP is the best way of getting a site out there. The sites that are
            at the top of search engine result pages have higher chances of
            reaching the target audience and this is exactly where search engine
            optimization SEO comes in.
          </p>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WHY OUR SEO SERVICES?
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          There are billions of web pages available making it extremely
          difficult to even know where to start when it comes to boosting the
          visibility of your web page or blog. We have been in the business of
          optimizing the popularity of startup pages and the established ones to
          ensure that their full potentials are surpassed with our effective and
          expert SEO services. Search Engine Optimization Services is unarguably
          the most effective and reliable technique for exposing your web pages
          and sites to the masses. It has shown to be more effective than one on
          one or word of mouth advertising method. The industry is so large and
          still growing at an astronomical rate, in the same vein; there is a
          mirage of SEO service providers out there and many more to come
          onboard. Therefore making a decision on the right service provider to
          engage is a very vital thing to do.
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid className="bg-white">
        <br />
        <br />
        <h2 className="text-center bg-textdark" data-aos="flip-up">
          WHY CHOOSE US?
        </h2>
        <br />
        <br />
        <Container className="align-items-center fs-5 text-center text-wrap">
          Softcode boasts of unparalleled expertise in this field which makes us
          stand out from the crowd. We know and understand how the industry
          works very well, we know our game and even that of our competitors. We
          are one of the best SEO service providers of this generation and we
          demonstrate this with our full site development approach. We have the
          best strategies and sophisticated techniques of building and
          optimizing sites to ensure that they reach their full potentials in a
          timely manner. We equally provides services for a range of different
          online platforms, from informational to e-commerce, or social media
          based, Softcode boasts of the right skill sets and the ability to
          drive consistent traffic to your site or web pages.
          <br />
          <br />
          We have unrivalled knowledge of making sites to be search engine
          favorites thereby promoting keyword ranking and boosting qualified
          traffic in real time. That is the reason why our clients always stand
          with us and place us above every other service provider both
          domestically and internationally. We are dedicated the business of
          taking start ups by hand and walk them through the journey of their
          discovery and popularity. Established sites count on us for putting an
          end to their frustrations and giving them real value for their money.
          They do not hesitate to express their satisfactions because to them,
          the difference has always been very clear.
        </Container>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container fluid>
        <br />
        <br />
        <h2
          className="text-center bg-textlight"
          data-aos="flip-left"
        >
          WHAT WE OFFER
        </h2>
        <br />
        <br />
        <Container className="text-white text-wrap text-center fs-5">
          <ListGroup className="text-start">
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;We have different packages for different categories of
              websites to accommodate everyone. The best part is that our
              packages are very comprehensive and provide between 6 to 50
              keywords ideal for both a small start-up site and even large and
              well established sites that intend to achieve optimum visibility.
              These SEO packages comprise a range of intermittent site services,
              such as major directory submissions, HTML optimization, and social
              bookmarks.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;One of our major aims at Softcode is to consistently advance
              our development services whilst improving our clients’ site to
              attain their fullest potentials. Our expert team and unrivalled
              knowledge puts us on top of our game and second to none in the
              industry. We are very committed to quality service delivery
              because we see ourselves as stakeholders with our clients and not
              just service provider. This is so because we know that our
              client’s success is our success and pride too.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;We have dedicated quality control department that ensures
              that all of our services are excellent and web ready. Generally,
              it is our expertise in Search Engine Optimization Services and the
              professional services delivered by our high rated staff that makes
              Softcode a sort after Search Engine Optimization SEO Services
              provider domestically and internationally.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;Our skilled team of consultants treats each client
              personally and handles every site on an individual basis to ensure
              that their peculiarities are well factored into the entire
              process. This is our approach of making certain that every clients
              and website is getting the needed tailor made services necessary
              to catapult them to limelight.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;We have the ability to assess the site and the owners and
              provide the best possible professional advice that is not one-size
              fits all in nature. We do this by considering the industry, the
              niche, and a whole lot of things that can contribute to success or
              failure. This experience is one of the reasons we have repeated
              businesses with our clients. Softcode boasts of unmatched
              performance and success and will always strive to do things even
              better because we know that we are in a fast changing environment
              and cannot afford to remain where we are today.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;We understand that expert knowledge, professional service
              and high quality delivery are all important but we didn’t
              sacrifice affordability. To us, quality is nothing without a fair
              cost so we strive to be very flexible and accommodating with our
              pricing to ensure that we always have mutually beneficial business
              relationship with our clients. We have always maintained this
              culture and that is why we have consistently grown and succeeded
              together with our clients over the years. Long term and healthy
              business relationship with clients is our biggest asset.
            </ListGroup.Item>
            <ListGroup.Item>
              <BsArrowReturnRight />
              &nbsp;Our SEO services are fairly priced with different packages
              that are well tailored to accommodate different categories of
              clients. Our customer care services are top notch; from email to
              telephone services your interest is always our priority.
            </ListGroup.Item>
          </ListGroup>
          <br />
          <br />
          <b>
            Feel free to contact us for pricing and partnership information.
          </b>
        </Container>
        <br />
        <br />
      </Container>
    </Layout>
  )
}

export default seo
